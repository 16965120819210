import { render, staticRenderFns } from "./safetyGearBuyRequestDetail.vue?vue&type=template&id=34355a44"
import script from "./safetyGearBuyRequestDetail.vue?vue&type=script&lang=js"
export * from "./safetyGearBuyRequestDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\강이화\\구영테크\\GuyoungFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34355a44')) {
      api.createRecord('34355a44', component.options)
    } else {
      api.reload('34355a44', component.options)
    }
    module.hot.accept("./safetyGearBuyRequestDetail.vue?vue&type=template&id=34355a44", function () {
      api.rerender('34355a44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sop/sas/safetyGearBuyRequestDetail.vue"
export default component.exports